import { Box, styled } from '@mui/material';

import Bg from 'assets/images/bg.png';

export const Container = styled(Box)(({ theme }) => ({
  backgroundImage: `url(${Bg})`,
  backgroundSize: 'cover',
  backgroundPosition: 'bottom',
  borderBottomRightRadius: 90,
  borderBottomLeftRadius: 90,
  [theme.breakpoints.down('md')]: {
    borderBottomRightRadius: 0,
    borderBottomLeftRadius: 0,
  },
}));

export const Content = styled(Box)(({ theme }) => ({
  width: '100%',
  maxWidth: 1440,
  display: 'grid',
  gridTemplateColumns: '680px auto',
  margin: '0 auto',
  padding: theme.spacing(25),
  [theme.breakpoints.down('lg')]: {
    gridTemplateColumns: 'none',
    padding: theme.spacing(15, 4),
  },
}));

export const WalletGifStyled = styled(Box)(({ theme }) => ({
  width: '100%',
  maxWidth: 260,
  minHeight: 560,
  overflow: 'hidden',
  borderRadius: 44,
  border: `5px solid ${theme.palette.primary.main}`,
  backgroundColor: theme.palette.grey['600'],
  placeSelf: 'end',
  boxShadow: '0px 4px 24px 0px rgba(0, 0, 0, 0.25)',
  [theme.breakpoints.down('lg')]: {
    placeSelf: 'center',
  },
  [theme.breakpoints.down('md')]: {
    maxWidth: 335,
  },
}));
