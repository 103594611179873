import { Box, styled } from '@mui/material';
import { rgba } from 'polished';

export const Container = styled(Box)(({ theme }) => ({
  width: '100%',
  borderTopRightRadius: 90,
  borderTopLeftRadius: 90,
  backgroundColor: theme.palette.background.paper,
  padding: theme.spacing(24, 0),
  [theme.breakpoints.down('md')]: {
    borderTopRightRadius: 60,
    borderTopLeftRadius: 60,
    padding: theme.spacing(15, 5),
  },
}));

export const SocialContainer = styled(Box)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(auto-fill, 180px)',
  justifyContent: 'center',
  gap: theme.spacing(6),
  width: '100%',
  maxWidth: 1440,
  margin: '0 auto',
  padding: theme.spacing(0, 25),
  [theme.breakpoints.down('lg')]: {
    gap: theme.spacing(10),
  },
  [theme.breakpoints.down('md')]: {
    justifyContent: 'center',
  },
}));

export const SocialItem = styled(Box)(({ theme }) => ({
  width: '100%',
  maxWidth: 180,
  borderRadius: 28,
  border: `3px solid ${theme.palette.secondary.main}`,
  backgroundColor: theme.palette.background.default,
  padding: theme.spacing(5.6, 2),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  gap: theme.spacing(5),
  opacity: 1,
  transition: '.6s ease',
  '&:hover': {
    cursor: 'pointer',
    opacity: 0.7,
    border: `3px solid ${theme.palette.primary.main}`,
    backgroundColor: rgba(theme.palette.background.default, 0.7),
  },
}));
