import React from 'react';

import Layout from './layout';
import { PageContainer } from './layout/styles';
import About from './scenes/About';
import Chains from './scenes/Chains';
import Download from './scenes/Download';
import Home from './scenes/Home';
import HowItWorks from './scenes/HowItWorks';
import Social from './scenes/Social';
import WhyDollet from './scenes/WhyDollet';

function App() {
  return (
    <Layout>
      <PageContainer>
        <Home />
      </PageContainer>
      <PageContainer fullWidth>
        <Chains />
      </PageContainer>
      <PageContainer>
        <WhyDollet />
      </PageContainer>
      <PageContainer fullWidth>
        <HowItWorks />
      </PageContainer>
      <PageContainer fullWidth>
        <About />
      </PageContainer>
      <PageContainer>
        <Download />
      </PageContainer>
      <PageContainer fullWidth>
        <Social />
      </PageContainer>
    </Layout>
  );
}

export default App;
