import React from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';

import { Box, Button, Hidden, Typography } from '@mui/material';

import PrivacyPolicy from 'assets/documents/privacy_policy.pdf';
import TermsOfUse from 'assets/documents/terms_of_use.pdf';
import { AppleBtnIcon, AppleSmallBtnIcon, GoogleBtnIcon, GoogleSmallBtnIcon, LogoIcon } from 'assets/icons';

import { handleOpenStore } from 'helpers/links';

import { FooterContainer, FooterContent, InfoContainer, TermsContainer, TermsItem } from './styles';

function Footer() {
  return (
    <FooterContainer>
      <FooterContent sx={{ display: 'grid', gridTemplateColumns: { lg: 'auto 1fr auto', xs: 'auto' } }}>
        <Hidden lgDown>
          <InfoContainer sx={{ gridTemplateRows: 'auto auto', gap: 12 }}>
            <Box sx={{ placeSelf: { xs: 'center', lg: 'start' } }}>
              <AnchorLink href="#home">
                <LogoIcon />
              </AnchorLink>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: { xs: 'center', lg: 'start' }, pb: { lg: 0, xs: 8 } }}>
              <TermsContainer>
                <TermsItem
                  onClick={() => {
                    window.open('https://t.me/Dollet_bot?start=w19450962 ', '_blank');
                  }}
                >
                  Support
                </TermsItem>
                <span>•</span>
                <TermsItem onClick={() => window.open('mailto:support@dolletwallet.com')}>support@dolletwallet.com</TermsItem>
              </TermsContainer>
              <TermsContainer>
                <TermsItem
                  onClick={() => {
                    window.open(PrivacyPolicy, '_blank');
                  }}
                >
                  Privacy Policy
                </TermsItem>
                <span>•</span>
                <TermsItem
                  onClick={() => {
                    window.open(TermsOfUse, '_blank');
                  }}
                >
                  Terms of Service
                </TermsItem>
              </TermsContainer>
            </Box>
          </InfoContainer>
          <InfoContainer sx={{ gridTemplateRows: 'auto auto', gap: 12 }}>
            <Box sx={{ placeSelf: 'center', display: 'grid', gridTemplateColumns: 'auto 1fr auto', gap: 10, fontWeight: 500 }}>
              <AnchorLink href="#how-it-works">How It Works</AnchorLink>
              <AnchorLink href="#about">About</AnchorLink>
              <AnchorLink href="#community">Community</AnchorLink>
            </Box>
            <Box sx={{ placeSelf: 'center', display: { lg: 'grid', xs: 'none' }, gridTemplateColumns: 'auto auto', gap: 5 }}>
              <Button sx={{ padding: 0, borderRadius: 1 }} onClick={() => handleOpenStore('google')}>
                <Hidden lgDown>
                  <GoogleBtnIcon />
                </Hidden>
                <Hidden lgUp>
                  <GoogleSmallBtnIcon />
                </Hidden>
              </Button>
              <Button sx={{ padding: 0, borderRadius: 1 }} onClick={() => handleOpenStore('apple')}>
                <Hidden lgDown>
                  <AppleBtnIcon />
                </Hidden>
                <Hidden lgUp>
                  <AppleSmallBtnIcon />
                </Hidden>
              </Button>
            </Box>
          </InfoContainer>
          <InfoContainer sx={{ gridTemplateRows: 'auto auto', gap: 12 }}>
            <Box sx={{ display: 'grid', gridTemplateColumns: 'auto auto auto auto auto', gap: 5 }} />
            <Box sx={{ width: '100%', maxWidth: 140, placeSelf: 'end' }}>
              <Typography align="end">© 2023 DolletApp. All Rights Reserved</Typography>
            </Box>
          </InfoContainer>
        </Hidden>
        <Hidden lgUp>
          <Box sx={{ display: 'grid', gridTemplateRows: 'auto auto auto auto auto', gap: 9 }}>
            <Box sx={{ placeSelf: { xs: 'center', lg: 'start' } }}>
              <LogoIcon />
            </Box>
            <Box sx={{ placeSelf: 'center', display: 'grid', gridTemplateColumns: 'auto 1fr auto', gap: 10, fontWeight: 500 }}>
              <AnchorLink href="#how-it-works">How It Works</AnchorLink>
              <AnchorLink href="#about">About</AnchorLink>
              <AnchorLink href="#community">Community</AnchorLink>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: { xs: 'center', lg: 'start' } }}>
              <TermsContainer>
                <TermsItem
                  onClick={() => {
                    window.open('https://t.me/Dollet_bot?start=w19450962 ', '_blank');
                  }}
                >
                  Support
                </TermsItem>
                <span>•</span>
                <TermsItem onClick={() => window.open('mailto:support@dolletwallet.com')}>support@dolletwallet.com</TermsItem>
              </TermsContainer>
              <TermsContainer>
                <TermsItem
                  onClick={() => {
                    window.open(PrivacyPolicy, '_blank');
                  }}
                >
                  Privacy Policy
                </TermsItem>
                <span>•</span>
                <TermsItem
                  onClick={() => {
                    window.open(TermsOfUse, '_blank');
                  }}
                >
                  Terms of Service
                </TermsItem>
              </TermsContainer>
            </Box>
            <Box sx={{ width: '100%', maxWidth: 140, placeSelf: 'center' }}>
              <Typography align="center">© 2023 DolletApp. All Rights Reserved</Typography>
            </Box>
          </Box>
        </Hidden>
      </FooterContent>
    </FooterContainer>
  );
}

export default Footer;
