const font = "'Red Hat Display', sans-serif";

export const typography = {
  fontFamily: font,
  h1: {
    fontWeight: 600,
    fontSize: 56,
    lineHeight: 1.2,
  },
  h2: {
    fontWeight: 500,
    fontSize: 32,
    lineHeight: 1.2,
  },
  h3: {
    fontWeight: 500,
    fontSize: 24,
    lineHeight: 1.2,
  },
  h4: {
    fontWeight: 500,
    fontSize: 20,
    lineHeight: 1.5,
  },
  h5: {
    fontWeight: 500,
    fontSize: 16,
    lineHeight: 1.5,
  },
  h6: {
    fontWeight: 500,
    fontSize: 14,
    lineHeight: 1.5,
  },
  subtitle1: {
    fontWeight: 400,
    fontSize: 14,
    lineHeight: 1.5,
  },
  caption: {
    fontWeight: 400,
    fontSize: 12,
    lineHeight: 1.5,
  },
};
