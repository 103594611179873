import { Box, styled } from '@mui/material';

export const Container = styled(Box)(({ theme }) => ({
  margin: theme.spacing(0, 0, 25),
  width: '100%',
  background: theme.palette.grey['700'],
  padding: theme.spacing(6.4, 0),
  overflow: 'hidden',
  '& .slick-slide': {
    margin: '0 16px',
    width: 'min-content !important',
    '&.slick-active': {
      width: 'min-content !important',
    },
  },
  '& .slick-list': {
    margin: '0 -16px',
  },
  [theme.breakpoints.down('md')]: {
    margin: 0,
  },
}));
