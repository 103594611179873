import React from 'react';
import Slider from 'react-slick';

import Chain from 'shared/components/Сhain';

import { ArbitrumIcon, BinanceIcon, BitcoinIcon, EthereumIcon, OptimismIcon, PolygonIcon, SolanaIcon, TronIcon } from 'assets/icons';

import { Container } from './styles';

const chains = [
  { icon: <BinanceIcon />, name: 'Binance Smart Сhain' },
  { icon: <BitcoinIcon />, name: 'Bitcoin' },
  { icon: <PolygonIcon />, name: 'Polygon' },
  { icon: <SolanaIcon />, name: 'Solana' },
  { icon: <TronIcon />, name: 'Tron' },
  { icon: <EthereumIcon />, name: 'Ethereum' },
  { icon: <OptimismIcon />, name: 'Optimism' },
  { icon: <ArbitrumIcon />, name: 'Arbitrum' },
];

const settings = {
  autoplay: true,
  arrows: false,
  swipeToSlide: false,
  swipe: false,
  dots: false,
  speed: 5000,
  autoplaySpeed: 0,
  cssEase: 'linear',
  slidesToShow: 7,
  variableWidth: true,
  pauseOnHover: false,
  responsive: [
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 5,
      },
    },
    {
      breakpoint: 900,
      settings: {
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
};

function Chains() {
  return (
    <Container>
      <Slider {...settings}>
        {chains.map(({ icon, name }, index) => (
          <Chain key={index} icon={icon} name={name} />
        ))}
      </Slider>
    </Container>
  );
}

export default Chains;
