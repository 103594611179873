import { Box, styled } from '@mui/material';

export const LayoutContainer = styled(Box)(({ theme }) => ({
  height: '100%',
  width: '100%',
  minHeight: '100vh',
  gridTemplateRows: ' auto 1fr auto',
  padding: theme.spacing(15, 0, 45),
  [theme.breakpoints.down('lg')]: {
    padding: theme.spacing(15, 0, 100),
  },
  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(15, 0, 100),
  },
}));

export const PageContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'fullWidth',
})(({ fullWidth, theme }) => ({
  width: '100%',
  maxWidth: fullWidth ? '100%' : 1440,
  margin: '0 auto',
  padding: fullWidth ? 0 : theme.spacing(0, 25),
  [theme.breakpoints.down('md')]: {
    padding: fullWidth ? 0 : theme.spacing(0, 4),
  },
}));
