import React from 'react';

import { Box, Button, Hidden, Typography } from '@mui/material';
import Video from 'shared/components/Video';

import { AppleBtnIcon, AppleSmallBtnIcon, GoogleBtnIcon, GoogleSmallBtnIcon } from 'assets/icons';
import Wallet2 from 'assets/images/home/wallet-home-2.png';
import Wallet3 from 'assets/images/home/wallet-home-3.png';
import WalletHome from 'assets/images/home.mp4';

import { handleOpenStore } from 'helpers/links';

import { Container, WalletGifStyled, WalletStyled } from './styles';

function Home() {
  return (
    <Container id="home">
      <Box
        sx={{
          display: 'grid',
          gridTemplateRows: 'auto auto auto 1fr',
          gap: 6,
          paddingTop: 6,
          paddingBottom: 6,
          placeSelf: { xs: 'center', lg: 'start' },
          gridRow: { xs: 2, lg: 'none' },
        }}
      >
        <Typography variant="h1" sx={{ fontSize: { xs: 44, md: 56 } }}>
          Welcome! I’m Dollet
        </Typography>
        <Typography variant="h2" sx={{ fontSize: { xs: 28, md: 32 } }}>
          Your multi-chain crypto wallet with <br /> a bridge and DeFi functionality
        </Typography>
        <Typography variant="h5" color="grey.300">
          I’m here to protect your crypto. With my secure wallet, you can be sure that your digital assets are safe and sound
        </Typography>
        <Box sx={{ display: 'flex', gap: { lg: 5, md: 2, xs: 2 }, marginTop: 'auto', pt: { lg: 15, md: 8, xs: 2 }, justifyContent: { xs: 'center', lg: 'inherit' } }}>
          <Button sx={{ padding: 0, borderRadius: 1 }} onClick={() => handleOpenStore('google')}>
            <Hidden lgDown>
              <GoogleBtnIcon />
            </Hidden>
            <Hidden lgUp>
              <GoogleSmallBtnIcon />
            </Hidden>
          </Button>
          <Button sx={{ padding: 0, borderRadius: 1 }} onClick={() => handleOpenStore('apple')}>
            <Hidden lgDown>
              <AppleBtnIcon />
            </Hidden>
            <Hidden lgUp>
              <AppleSmallBtnIcon />
            </Hidden>
          </Button>
        </Box>
      </Box>
      <Box sx={{ placeSelf: { xs: 'center', lg: 'end' }, gridRow: { sx: 1, lg: 'none' } }}>
        <Box sx={{ display: 'grid', gridTemplateColumns: 'auto auto', gap: 10, position: 'relative' }}>
          <WalletStyled>
            <img src={Wallet2} alt="wallet-2" />
          </WalletStyled>
          <WalletStyled>
            <img src={Wallet3} alt="wallet-3" />
          </WalletStyled>
          <WalletGifStyled>
            <Video src={WalletHome} isHome={true} />
          </WalletGifStyled>
        </Box>
      </Box>
    </Container>
  );
}

export default Home;
