import { styled } from '@mui/material';

export const VideoStyled = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isHome',
})(({ isHome, theme }) => ({
  '& video': {
    pointerEvents: 'none',
    width: isHome ? 240 : 260,
    minHeight: isHome ? 515 : 560,
    [theme.breakpoints.down('md')]: {
      width: isHome ? 164 : 335,
      minHeight: isHome ? 350 : 'auto',
    },
  },
}));

export const ImageStyled = styled('img', {
  shouldForwardProp: (prop) => prop !== 'isHome',
})(({ isHome, theme }) => ({
  width: isHome ? 240 : 356,
  minHeight: isHome ? 515 : 560,
  [theme.breakpoints.down('md')]: {
    width: isHome ? 164 : 335,
    minHeight: isHome ? 350 : 'auto',
  },
}));
