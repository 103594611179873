export const components = {
  MuiToolbar: {
    styleOverrides: {
      root: ({ theme }) => ({
        minHeight: 72,
        [theme.breakpoints.up('sm')]: {
          minHeight: 72,
        },
      }),
    },
  },
  MuiListItemButton: {
    styleOverrides: {
      root: ({ theme }) => ({
        padding: theme.spacing(1.6, 0),
      }),
    },
  },
  MuiListItemText: {
    styleOverrides: {
      root: ({ theme }) => ({
        color: theme.palette.primary.main,
      }),
    },
  },
  MuiDrawer: {
    styleOverrides: {
      paper: {
        left: 0,
      },
    },
  },
  MuiAppBar: {
    styleOverrides: {
      root: ({ theme }) => ({
        boxShadow: 'none',
        padding: theme.spacing(0, 25),
        [theme.breakpoints.down('lg')]: {
          padding: theme.spacing(0, 4),
        },
      }),
    },
  },
  MuiButton: {
    styleOverrides: {
      root: ({ theme }) => ({
        padding: theme.spacing(1.6, 3.2),
        borderRadius: theme.spacing(2),
        textTransform: 'capitalize',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        fontWeight: 500,
      }),
      startIcon: ({ theme }) => ({
        margin: theme.spacing(0, 3, 0, 0),
      }),
      outlinedPrimary: ({ theme }) => ({
        boxSizing: 'border-box',
        border: `2px solid ${theme.palette.secondary.main}`,
        color: theme.palette.secondary.main,
        '&:hover': {
          border: `2px solid ${theme.palette.secondary.main}`,
        },
        '&:disabled': {
          color: theme.palette.grey[300],
          background: theme.palette.grey[600],
        },
      }),
      containedPrimary: ({ theme }) => ({
        backgroundColor: theme.palette.secondary.main,
        '&:hover': {
          backgroundColor: theme.palette.secondary.main,
        },
        '&:disabled': {
          color: theme.palette.grey[300],
          background: theme.palette.grey[600],
        },
      }),
    },
  },
};
