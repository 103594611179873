export const handleOpenSocialLinks = (social) => {
  let url = '';
  switch (social) {
    case 'instagram':
      url = 'https://www.instagram.com/wallet.dollet/';
      break;
    case 'twitter':
      url = 'https://twitter.com/DolletWallet';
      break;
    case 'discord':
      url = 'https://discord.com/invite/WdbH6nKRuA';
      break;
    case 'threads':
      url = 'https://www.threads.net/@wallet.dollet';
      break;
    default:
      url = 'https://t.me/wallet_dollet';
      break;
  }
  return window.open(url, '_blank');
};

export const handleOpenStore = (store) => {
  const url = store === 'apple' ? 'https://apps.apple.com/us/app/dollet/id6451143361' : 'https://play.google.com/store/apps/details?id=com.dolllet.mainnet';

  return window.open(url, '_blank');
};
