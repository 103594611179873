import { Box, styled, Typography } from '@mui/material';
import { rgba } from 'polished';

export const InfoContainer = styled(Box)(({ theme }) => ({
  display: 'grid',
}));

export const ImageStyled = styled('img')(({ theme }) => ({
  width: '100%',
  maxWidth: 200,
}));

export const TermsContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  '& span': {
    padding: theme.spacing(0, 1.6),
  },
}));

export const TermsItem = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  transition: 'color 0.6s ease',
  '&:hover': {
    cursor: 'pointer',
    color: theme.palette.secondary.main,
  },
}));

export const FooterContainer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  width: '100%',
  position: 'absolute',
  left: 0,
  right: 0,
}));

export const FooterContent = styled(Box)(({ theme }) => ({
  width: '100%',
  maxWidth: 1440,
  margin: '0 auto',
  padding: theme.spacing(8, 0),
  [theme.breakpoints.down(1536)]: {
    padding: theme.spacing(8, 25),
  },
  [theme.breakpoints.down('lg')]: {
    padding: theme.spacing(11, 3),
  },
}));

export const SocialItem = styled(Box)(({ theme }) => ({
  '& path': {
    opacity: 1,
    transition: '.6s ease',
  },
  '&:hover': {
    cursor: 'pointer',
    '& path': {
      opacity: 0.5,
    },
  },
}));
