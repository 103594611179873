import { AppBar, styled, Toolbar } from '@mui/material';

export const AppBarStyled = styled(AppBar, {
  shouldForwardProp: (prop) => prop !== 'isChangeBg',
})(({ isChangeBg, theme }) => ({
  backgroundColor: 'transparent',
  ...(isChangeBg && {
    backgroundColor: isChangeBg ? theme.palette.background.default : 'transparent',
    transition: 'all 0.8s ease',
    WebkitTransition: 'all 0.8s ease',
    MozTransition: 'all 0.8s ease',
  }),
}));

export const ToolbarStyled = styled(Toolbar)(({ theme }) => ({
  transform: 'translateX(-50%)',
  left: '50%',
  width: '100%',
  maxWidth: 1440,
  display: 'grid',
  gridTemplateColumns: '1fr 367px auto',
  [theme.breakpoints.down('lg')]: {
    gridTemplateColumns: '1fr auto auto',
  },
}));
