import { Box, styled } from '@mui/material';

import DownloadAppBg from 'assets/images/download-app-bg.png';

export const Container = styled(Box)(({ theme }) => ({
  width: '100%',
  borderRadius: 40,
  background: `url(${DownloadAppBg})`,
  backgroundSize: 'cover',
  padding: theme.spacing(28, 0, 28, 15),
  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(10, 3),
  },
}));

export const BtnsStore = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(4),
  marginTop: 'auto',
  justifyContent: 'inherit',
  [theme.breakpoints.down('md')]: {
    gap: theme.spacing(2),
    justifyContent: 'center',
    '& svg': {
      width: 140,
    },
  },
}));
