import React from 'react';

import Footer from 'shared/components/Footer';
import Header from 'shared/components/Header';

import { LayoutContainer } from './styles';

function Layout({ children }) {
  return (
    <LayoutContainer>
      <Header />
      {children}
      <Footer />
    </LayoutContainer>
  );
}

export default Layout;
