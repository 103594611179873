import React from 'react';

import { Box, Hidden, Typography } from '@mui/material';
import Video from 'shared/components/Video';

import WalletAbout from 'assets/images/about.mp4';

import { Container, Content, WalletGifStyled } from './styles';

function About() {
  return (
    <Container id="about">
      <Hidden lgDown>
        <Content>
          <Box sx={{ display: 'grid', gridTemplateRows: 'auto auto auto', gap: 7, placeSelf: 'center' }}>
            <Typography variant="h1">About me</Typography>
            <Typography variant="h4">{`Meet Dollet, a blend of 'doll' and 'wallet', and your new best friend in the crypto world!`}</Typography>
            <Typography variant="h5">
              {`I'm more than just a smart doll; I'm your personal guide to secure and private crypto transactions. With me, you're in full control of your assets, and nothing happens without your say-so. I support all your favorite cryptocurrencies and more, but I like to keep things simple.`}
              <br />
              <br />
              {`My multi-chain functionality means you can manage all your assets in one place, no need for clutter or confusion. My team and I are passionate about the decentralized future, and we're here to give you the tools to join in.`}
              <br />
              <br />
              Cool, right?
            </Typography>
            <Typography variant="h4">Come experience the future of finance with me! Dollet - Your Wallet, Your Way.</Typography>
          </Box>
          <WalletGifStyled>
            <Video src={WalletAbout} isHome={false} />
          </WalletGifStyled>
        </Content>
      </Hidden>
      <Hidden lgUp>
        <Content>
          <Box sx={{ display: 'grid', gridTemplateRows: 'auto auto auto', gap: 7, placeSelf: 'center' }}>
            <Typography variant="h1" sx={{ fontSize: { xs: 44, lg: 56 } }}>
              About me
            </Typography>
            <Typography variant="h4">{`Meet Dollet, a blend of 'doll' and 'wallet', and your new best friend in the crypto world!`}</Typography>
            <WalletGifStyled>
              <Video src={WalletAbout} isHome={false} />
            </WalletGifStyled>
            <Typography variant="h5">
              {`I'm more than just a smart doll; I'm your personal guide to secure and private crypto transactions. With me, you're in full control of your assets, and nothing happens without your say-so. I support all your favorite cryptocurrencies and more, but I like to keep things simple.`}
              <br />
              <br />
              {`My multi-chain functionality means you can manage all your assets in one place, no need for clutter or confusion. My team and I are passionate about the decentralized future, and we're here to give you the tools to join in.`}
              <br />
              <br />
              Cool, right?
            </Typography>
            <Typography variant="h4">Come experience the future of finance with me! Dollet - Your Wallet, Your Way.</Typography>
          </Box>
        </Content>
      </Hidden>
    </Container>
  );
}

export default About;
