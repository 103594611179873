import { Box, styled } from '@mui/material';

export const Container = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  borderTopRightRadius: 90,
  borderTopLeftRadius: 90,
  marginTop: theme.spacing(25),
  padding: theme.spacing(25, 0),
  [theme.breakpoints.down('md')]: {
    borderTopRightRadius: 50,
    borderTopLeftRadius: 50,
    padding: theme.spacing(15, 0),
    marginTop: 0,
  },
}));

export const WalletContainer = styled(Box)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(auto-fill, 205px)',
  justifyContent: 'space-between',
  gap: theme.spacing(20),
  width: '100%',
  maxWidth: 1440,
  margin: '0 auto',
  padding: theme.spacing(0, 25),
  [theme.breakpoints.down('lg')]: {
    gap: theme.spacing(10),
  },
  [theme.breakpoints.down('md')]: {
    gap: theme.spacing(10),
    justifyContent: 'center',
  },
}));

export const WalletItem = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: theme.spacing(8),
  paddingTop: theme.spacing(23),
  '& img': {
    width: 205,
    borderRadius: 28,
    boxShadow: '0px 4px 24px 0px rgba(0, 0, 0, 0.25)',
  },
  [theme.breakpoints.down('md')]: {
    paddingTop: theme.spacing(10),
  },
}));
