import { rgba } from 'polished';

export const palette = {
  primary: {
    main: '#ffffff',
    dark: '#252429',
  },
  secondary: {
    main: '#6DFFD5',
  },
  background: {
    default: '#2B2938',
    paper: rgba(255, 255, 255, 0.1),
  },
  text: {
    primary: '#ffffff',
    secondary: '#252429',
  },
  grey: {
    300: '#B2B2B5',
    600: '#28282d',
    700: '#1D1B25',
  },
};
