import { Box, styled } from '@mui/material';

export const BoxStyled = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'grid',
  gridTemplateColumns: '44px auto',
  gap: theme.spacing(3),
  background: theme.palette.background.paper,
  borderRadius: 16,
  padding: theme.spacing(1.6, 3.2),
  maxWidth: 'min-content',
  '& svg, h6': {
    placeSelf: 'center',
  },
}));
