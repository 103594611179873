import { Box, styled } from '@mui/material';

export const Container = styled(Box)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '575px auto',
  padding: theme.spacing(21, 0),
  [theme.breakpoints.down('lg')]: {
    gap: theme.spacing(20),
    gridTemplateColumns: 'none',
    gridTemplateRows: '1fr auto',
  },
  [theme.breakpoints.down('md')]: {
    gap: theme.spacing(5),
    padding: theme.spacing(10, 0),
  },
}));

export const WalletGifStyled = styled(Box)(({ theme }) => ({
  width: '100%',
  maxWidth: 240,
  overflow: 'hidden',
  borderRadius: 40,
  border: `5px solid ${theme.palette.primary.main}`,
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  backgroundColor: theme.palette.grey['600'],
  boxShadow: '0px 4px 24px 0px rgba(0, 0, 0, 0.25)',
  [theme.breakpoints.down('md')]: {
    maxWidth: 164,
    height: 350,
  },
}));

export const WalletStyled = styled(Box)(({ theme }) => ({
  width: '100%',
  maxWidth: 205,
  height: 440,
  boxShadow: '0px 4px 24px 0px rgba(0, 0, 0, 0.25)',
  borderRadius: 28,
  '& img': {
    width: 205,
    height: 440,
  },
  [theme.breakpoints.down('md')]: {
    maxWidth: 140,
    height: 300,
    '& img': {
      width: 140,
      height: 300,
      objectFit: 'cover',
    },
  },
}));
