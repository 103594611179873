import React from 'react';

import { Box, Button, Hidden, Typography } from '@mui/material';

import Certik from 'assets/documents/certik.pdf';
import DeusSec from 'assets/documents/deus_sec.pdf';
import {
  AppleBtnIcon,
  AppleSmallBtnIcon,
  CertikIcon,
  CertikSmallIcon,
  GoogleBtnIcon,
  GoogleSmallBtnIcon,
  KaizenFinanceSmallSoonIcon,
  KaizenFinanceSoonIcon,
  TelegramBtnIcon,
} from 'assets/icons';

import { handleOpenStore } from 'helpers/links';

import { BtnsStore, Container } from './styles';

function Download() {
  return (
    <Box sx={{ pt: { lg: 25, md: 25, xs: 17 }, pb: { lg: 25, md: 25, xs: 17 } }}>
      <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', mb: { lg: 25, xs: 17 } }}>
        <Typography sx={{ fontSize: { lg: 56, md: 44, xs: 44 }, fontWeight: 600, textAlign: { xs: 'center', md: 'left' }, pb: 9 }}>Audited by</Typography>
        <Hidden mdUp>
          <Box sx={{ display: 'grid', alignItems: 'center', gridTemplateColumns: 'auto auto', gap: 9, width: '100%', maxWidth: 320 }}>
            <Box
              onClick={() => {
                window.open(Certik, '_blank');
              }}
              sx={{ '&:hover': { cursor: 'pointer' } }}
            >
              <CertikSmallIcon />
            </Box>
            <Box
              onClick={() => {
                window.open(DeusSec, '_blank');
              }}
              sx={{ '&:hover': { cursor: 'pointer' } }}
            >
              <KaizenFinanceSmallSoonIcon />
            </Box>
          </Box>
        </Hidden>
        <Hidden mdDown>
          <Box sx={{ display: 'grid', alignItems: 'center', gridTemplateColumns: 'auto auto', gap: 18, width: '100%', maxWidth: 485 }}>
            <Box
              onClick={() => {
                window.open(Certik, '_blank');
              }}
              sx={{ '&:hover': { cursor: 'pointer' } }}
            >
              <CertikIcon />
            </Box>
            <Box
              onClick={() => {
                window.open(DeusSec, '_blank');
              }}
              sx={{ '&:hover': { cursor: 'pointer' } }}
            >
              <KaizenFinanceSoonIcon />
            </Box>
          </Box>
        </Hidden>
      </Box>
      <Box id="download-app">
        <Container>
          <Box sx={{ display: 'grid', gridTemplateRows: 'auto auto auto', gap: 7, width: '100%', maxWidth: 485 }}>
            <Typography sx={{ fontSize: 44, fontWeight: 600, textAlign: { xs: 'center', md: 'left' } }} color="text.secondary">
              <Hidden lgDown>Download Dollet App</Hidden>
              <Hidden lgUp>
                Download <br />
                Dollet App
              </Hidden>
            </Typography>
            <Typography variant="h4" color="text.secondary" sx={{ textAlign: { xs: 'center', md: 'left' } }}>
              Let Dollet be your trusted companion on DeFi journey, ensuring your assets are always safe and secure
            </Typography>
            <BtnsStore>
              <Button sx={{ padding: 0, borderRadius: 1 }} onClick={() => handleOpenStore('google')}>
                <Hidden lgDown>
                  <GoogleBtnIcon />
                </Hidden>
                <Hidden lgUp>
                  <GoogleSmallBtnIcon />
                </Hidden>
              </Button>
              <Button sx={{ padding: 0, borderRadius: 1 }} onClick={() => handleOpenStore('apple')}>
                <Hidden lgDown>
                  <AppleBtnIcon />
                </Hidden>
                <Hidden lgUp>
                  <AppleSmallBtnIcon />
                </Hidden>
              </Button>
            </BtnsStore>
          </Box>
        </Container>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mt: { xs: 18, lg: 24 } }}>
        <Typography variant="h1" align="center">
          Do you wanna have more info?
        </Typography>
        <Typography variant="h4" sx={{ fontWeight: 700, mt: 6 }} align="center">
          Come to my telegram-bot with FAQ and personal support managers
        </Typography>
        <Button
          variant="contained"
          startIcon={<TelegramBtnIcon />}
          sx={{ mt: 12, width: '100%', height: 56, maxWidth: { xs: '100%', sm: 180 } }}
          onClick={() => {
            window.open('https://t.me/Dollet_bot?start=w19450962 ', '_blank');
          }}
        >
          Go To Dollet Bot
        </Button>
      </Box>
    </Box>
  );
}

export default Download;
