import { Box, styled } from '@mui/material';

export const Content = styled(Box)(({ theme }) => ({
  width: '100%',
  maxWidth: 988,
  borderRadius: 40,
  backgroundColor: theme.palette.background.paper,
  margin: '0 auto',
  display: 'grid',
  gridTemplateRows: 'auto auto auto',
  padding: theme.spacing(13, 25),
  gap: theme.spacing(12),
  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(13, 5),
    gap: theme.spacing(6),
  },
}));

export const Container = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(17, 0),
  },
}));

export const IconContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: theme.spacing(3),
  [theme.breakpoints.down('md')]: {
    gap: theme.spacing(1),
  },
  [theme.breakpoints.down('sm')]: {
    '& svg': {
      width: 80,
    },
  },
}));
