import React from 'react';

import { Box, Typography } from '@mui/material';

import { BoxStyled } from './styles';

function Chain({ icon, name }) {
  return (
    <BoxStyled>
      {icon}
      <Typography variant="subtitle1" whiteSpace="nowrap">
        {name}
      </Typography>
    </BoxStyled>
  );
}

export default Chain;
