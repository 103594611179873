import React from 'react';

import { Box, Typography } from '@mui/material';

import { BridgeIcon, StrategiesIcon, SwipeIcon } from 'assets/icons';

import { Container, Content, IconContainer } from './styles';

function WhyDollet() {
  return (
    <Container>
      <Content>
        <Box sx={{ placeSelf: 'center' }}>
          <Typography variant="h1" sx={{ fontSize: { xs: 44, md: 56 } }}>
            Why Dollet?
          </Typography>
          <Typography variant="h3" sx={{ fontSize: { xs: 20, md: 32 } }} align="center" paddingTop={5}>
            Because WOW Dollet
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: '1fr 1fr 1fr',
            gap: { xl: 10, lg: 6, md: 2, xs: 2 },
            width: '100%',
            maxWidth: { xl: 430, lg: 400, md: 360, xs: 360 },
            placeSelf: 'center',
            pr: { xs: 3, md: 5 },
            pl: { xs: 3, md: 5 },
          }}
        >
          <IconContainer
            sx={{
              placeSelf: 'start',
            }}
          >
            <SwipeIcon />
            <Typography varinat="h6" align="center" sx={{ fontSize: { lg: 14, xs: 12 } }}>
              Swap
            </Typography>
          </IconContainer>
          <IconContainer sx={{ placeSelf: 'center' }}>
            <BridgeIcon />
            <Typography varinat="h6" align="center" sx={{ fontSize: { lg: 14, xs: 12 } }}>
              Bridge
            </Typography>
          </IconContainer>
          <IconContainer sx={{ placeSelf: 'end' }}>
            <StrategiesIcon />
            <Typography varinat="h6" align="center" sx={{ fontSize: { lg: 14, xs: 12 } }}>
              DeFi Strategies
            </Typography>
          </IconContainer>
        </Box>
        <Box sx={{ placeSelf: 'center', pr: 3, pl: 3 }}>
          <Typography variant="h5" align="center">
            {`I'm more than just a toy. I'm your partner in wealth creation. Let's grow your crypto together and make your dreams a reality. Let me be your personal DeFi assistant, guiding you to make the best decisions.`}
          </Typography>
        </Box>
      </Content>
    </Container>
  );
}

export default WhyDollet;
