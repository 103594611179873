import React from 'react';

import { Box, Typography } from '@mui/material';

import { DiscordIcon, InstagramIcon, TelegramIcon, ThreadsIcon, TwitterIcon } from 'assets/icons';

import { handleOpenSocialLinks } from 'helpers/links';

import { Container, SocialContainer, SocialItem } from './styles';

function Social() {
  return (
    <Container id="community">
      <Typography variant="h1" align="center" sx={{ mb: 20, fontSize: { xs: 44, lg: 56 } }}>
        Waiting for you in my community
      </Typography>
      <SocialContainer>
        <SocialItem onClick={() => handleOpenSocialLinks('telegram')}>
          <TelegramIcon />
          <Typography variant="h3">Telegram</Typography>
        </SocialItem>
        <SocialItem onClick={() => handleOpenSocialLinks('twitter')}>
          <TwitterIcon />
          <Typography variant="h3">Twitter</Typography>
        </SocialItem>
        <SocialItem onClick={() => handleOpenSocialLinks('instagram')}>
          <InstagramIcon />
          <Typography variant="h3">Instagram</Typography>
        </SocialItem>
        <SocialItem onClick={() => handleOpenSocialLinks('discord')}>
          <DiscordIcon />
          <Typography variant="h3">Discord</Typography>
        </SocialItem>
        <SocialItem onClick={() => handleOpenSocialLinks('threads')}>
          <ThreadsIcon />
          <Typography variant="h3">Threads</Typography>
        </SocialItem>
      </SocialContainer>
    </Container>
  );
}

export default Social;
