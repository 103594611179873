import React from 'react';

import { Box, Typography } from '@mui/material';

import Wallet1 from 'assets/images/how-it-works/wallet-works-1.png';
import Wallet2 from 'assets/images/how-it-works/wallet-works-2.png';
import Wallet3 from 'assets/images/how-it-works/wallet-works-3.png';
import Wallet4 from 'assets/images/how-it-works/wallet-works-4.png';

import { capitalizeFirstLetter } from 'helpers/text';

import { Container, WalletContainer, WalletItem } from './styles';

function HowItWorks() {
  return (
    <Container id="how-it-works">
      <Typography variant="h1" align="center" sx={{ fontSize: { xs: 44, lg: 56 } }}>
        How It Works
      </Typography>
      <WalletContainer>
        <WalletItem>
          <img src={Wallet1} alt="wallet-1" />
          <Typography variant="h5" align="center">
            {capitalizeFirstLetter('Create your wallet with seed phase')}
          </Typography>
        </WalletItem>
        <WalletItem>
          <img src={Wallet2} alt="wallet-2" />
          <Typography variant="h5" align="center">
            {capitalizeFirstLetter('Control the balance of your Crypto and transaction history')}
          </Typography>
        </WalletItem>
        <WalletItem>
          <img src={Wallet3} alt="wallet-3" />
          <Typography variant="h5" align="center">
            {capitalizeFirstLetter(' Use the bridge functionality to transfer crypto')}
          </Typography>
        </WalletItem>
        <WalletItem>
          <img src={Wallet4} alt="wallet-4" />
          <Typography variant="h5" align="center">
            {capitalizeFirstLetter(' Deposit safely using the DeFi Strategy functionality')}
          </Typography>
        </WalletItem>
      </WalletContainer>
    </Container>
  );
}

export default HowItWorks;
