import React, { useEffect, useState } from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';

import { Box, Button, Drawer, IconButton } from '@mui/material';

import { CloseIcon, LogoSmallIcon, MenuIcon } from 'assets/icons';
import MenuBg from 'assets/images/menu-bg.png';

import { AppBarStyled, ToolbarStyled } from './styles';

function Header() {
  const [open, setState] = useState(false);
  const [isChangeBg, setIsChangeBg] = useState(false);

  useEffect(() => {
    window.addEventListener('scroll', handleChangeBgHeader);

    return () => {
      window.removeEventListener('scroll', handleChangeBgHeader);
    };
  }, []);

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setState(open);
  };

  const handleChangeBgHeader = () => {
    if (window.scrollY >= 80) {
      setIsChangeBg(true);
    } else {
      setIsChangeBg(false);
    }
  };

  return (
    <AppBarStyled position="fixed" isChangeBg={isChangeBg}>
      <ToolbarStyled disableGutters="true">
        <AnchorLink href="#home">
          <LogoSmallIcon />
        </AnchorLink>
        <Box
          sx={{
            gridTemplateColumns: 'auto auto auto',
            display: {
              xs: 'none',
              lg: 'grid',
            },
          }}
        >
          <AnchorLink sx={{ placeSelf: 'center' }} href="#how-it-works">
            How It Works
          </AnchorLink>
          <AnchorLink sx={{ placeSelf: 'center' }} href="#about">
            About
          </AnchorLink>
          <AnchorLink sx={{ placeSelf: 'center' }} href="#community">
            Community
          </AnchorLink>
        </Box>
        <Box
          ml={{ lg: 21, xs: 0 }}
          sx={{
            display: 'grid',
            gap: 5,
            gridTemplateColumns: 'auto auto',
          }}
        >
          <Button
            variant="outlined"
            sx={{
              display: {
                xs: 'none',
                lg: 'block',
              },
            }}
            onClick={() => {
              window.open('https://t.me/Dollet_bot?start=w19450962 ', '_blank');
            }}
          >
            Go To Dollet Bot
          </Button>
          <AnchorLink sx={{ placeSelf: 'center' }} href="#download-app">
            <Button variant="contained">Download App</Button>
          </AnchorLink>
        </Box>
        <IconButton
          edge="start"
          color="inherit"
          aria-label="open drawer"
          onClick={toggleDrawer(true)}
          sx={{
            padding: 0,
            display: {
              xs: 'block',
              lg: 'none',
            },
          }}
        >
          <MenuIcon />
        </IconButton>
      </ToolbarStyled>
      <Drawer anchor="right" open={open} onClose={toggleDrawer(false)} onOpen={toggleDrawer(true)}>
        <Box
          sx={{
            p: 5,
            height: 1,
            backgroundImage: `url(${MenuBg})`,
            backgroundSize: 'cover',
            display: 'grid',
            gridTemplateRows: 'min-content 1fr auto',
          }}
        >
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <AnchorLink href="#home" onClick={toggleDrawer(false)}>
              <LogoSmallIcon />
            </AnchorLink>
            <IconButton sx={{ p: 0 }}>
              <CloseIcon onClick={toggleDrawer(false)} />
            </IconButton>
          </Box>
          <Box sx={{ placeSelf: 'end start', display: 'grid', gridTemplateRows: 'auto auto auto', gap: 10, mb: 15, fontWeight: 500 }}>
            <AnchorLink href="#how-it-works" onClick={toggleDrawer(false)}>
              How It Works
            </AnchorLink>
            <AnchorLink href="#about" onClick={toggleDrawer(false)}>
              About
            </AnchorLink>
            <AnchorLink href="#community" onClick={toggleDrawer(false)}>
              Community
            </AnchorLink>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
            }}
          >
            <Button
              variant="outlined"
              sx={{ mb: 3 }}
              onClick={() => {
                window.open('https://t.me/Dollet_bot?start=w19450962 ', '_blank');
              }}
            >
              Go To Dollet Bot
            </Button>
            <AnchorLink href="#download-app">
              <Button variant="contained" sx={{ width: '100%' }} onClick={toggleDrawer(false)}>
                Download App
              </Button>
            </AnchorLink>
          </Box>
        </Box>
      </Drawer>
    </AppBarStyled>
  );
}

export default Header;
