import { createTheme } from '@mui/material/styles';

import { components } from './components';
import { palette } from './palette';
import { typography } from './typography';

const theme = createTheme({
  spacing: 5,
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1440,
    },
  },
  typography,
  palette,
  components,
});
export default theme;
